<template>
  <div class="bg-white container-fluid">
    <headerv-2></headerv-2>

    <div>
      <main class="content container-fluid mx-auto px-5 lg:px-10">
        <section class="service-bn">
          <div class="service-bn-container container">
            <h1 class="title">Find the Best Lawyers</h1>                 
            <p class="p-info-nd">
              Share your needs with us, refine your search by selecting a
              practise area and county
            </p>          
           <a href="#search">
            <div class="arrow-dwn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
                width="30"
                height="30"
                class=""
              >
                <defs></defs>
                <path
                  class="fa-primary"
                  d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 402.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 476.9 232.2 480 224 480z"
                ></path>
                <path
                  class="fa-secondary"
                  d="M224 288C215.8 288 207.6 284.9 201.4 278.6l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 210.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 284.9 232.2 288 224 288z"
                ></path>
              </svg>
            </div>
           </a>
          </div>
        </section>
        <div class="s-listings">
  <div>
    <div class="relative z-40 lg:hidden" role="dialog" aria-modal="true" v-if="showFiltersMobile">
      <div class="fixed inset-0 bg-black bg-opacity-25"></div>

      <div class="fixed inset-0 z-40 flex">
        <div class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
          <div class="flex items-center justify-between px-4">
            <h2 class="text-lg font-medium text-gray-900">Filters</h2>
            <button type="button" class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"  @click="showFiltersMobile=false">
              <span class="sr-only">Close menu</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!-- Filters -->
          <form class="mt-4 border-t border-gray-200">
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" class="flex w-full items-center justify-between px-2 bg-red-500 text-white py-3 text-sm  hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">Filter By Primary Practise Areas</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="areasOpen=true">
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="areasOpen=false">
                      <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </button>
              </h3>
          
              <div class="p-6 bg-white" id="filter-section-0" v-if="areasOpen">
                <div class="space-y-4">
                  <div class="flex items-center" v-for="(practise,index) of practiseAreas" :key="index">
                    <input v-model="selectedPracticeAreas" :id="'filter-color-' + index" :name="practise" :value="practise" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"  @change="scrollToTop">
            <label :for="'filter-color-' + index" class="ml-3 text-sm text-gray-600">{{ practise }}</label>
                  </div>

                </div>
              </div>
            </div>
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" class="flex w-full items-center justify-between px-2 bg-red-500 text-white py-3 text-sm hover:text-gray-500" aria-controls="filter-section-1" aria-expanded="false">
                  <span class="font-medium text-gray-900">Filter By State</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="statesOpen=true">
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="statesOpen=false">
                      <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              <div class="p-6 bg-white" id="filter-section-1" v-if="statesOpen">
                <div class="space-y-4">
                  <div class="flex items-center" v-for="(state,index) of counties" :key="index">
                    <input v-model="selectedStates" :id="'filter-category-' + index" :name="state" :value="state" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" @change="  scrollToTop">
                    <label :for="'filter-category-' + index" class="ml-3 text-sm text-gray-600">{{ state }}</label>
                  </div>
                </div>
              </div>
            </div>          
          </form>
        </div>
      </div>
    </div>

    <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <section aria-labelledby="products-heading" class="pb-24 pt-6">
        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
          <!-- Filters -->
          <form class="hidden lg:block my-4">
            <div class="side-lft">
              <div class="side-lg-card">
                <div class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    fill="currentColor"
                    width="16"
                    class="ml-1 inline-flex"
                  >
                    <path
                      d="M144 416c-17.67 0-32 14.33-32 32s14.33 32.01 32 32.01s32-14.34 32-32.01S161.7 416 144 416zM211.2 32H104C46.66 32 0 78.66 0 136v16C0 165.3 10.75 176 24 176S48 165.3 48 152v-16c0-30.88 25.12-56 56-56h107.2C244.7 80 272 107.3 272 140.8c0 22.66-12.44 43.27-32.5 53.81L167 232.8C137.1 248 120 277.9 120 310.6V328c0 13.25 10.75 24.01 24 24.01S168 341.3 168 328V310.6c0-14.89 8.188-28.47 21.38-35.41l72.47-38.14C297.7 218.2 320 181.3 320 140.8C320 80.81 271.2 32 211.2 32z"
                    ></path>
                  </svg>
                </div>
                <div class="card-info">
                  <h2>Not sure how to proceed?</h2>
                  <p>Let us find the right lawyers for you.</p>
                </div>
                <div class="icon-nd">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    fill="currentColor"
                    width="60"
                    class="inline-flex mb-4 text-blue-100"
                  >
                    <path
                      d="M637.9 203.9l-8-13.9c-4.4-7.7-14.2-10.3-21.9-5.9l-96.7 56.4c-3.7-27.4-26.9-48.6-55.3-48.6H304v64c0 17.6-14.3 32-32 32s-32-14.4-32-32v-86.3c0-11 5.7-21.3 15-27.1l33.4-20.9c10.2-6.4 21.9-9.7 33.9-9.7h105.3l119.8-68.2c7.7-4.4 10.4-14.1 6-21.8L545.5 8c-4.4-7.7-14.1-10.4-21.8-6L415 64h-92.7c-21 0-41.5 5.9-59.3 17l-33.5 20.9c-18.3 11.4-30.6 29.4-35.2 49.8l-59.4 35.6C110.8 201.8 96 227.9 96 256.1v34.1L8 341c-7.7 4.4-10.3 14.2-5.9 21.9l8 13.9c4.4 7.7 14.2 10.3 21.9 5.9L144 318v-61.8c0-11.3 5.9-21.8 15.6-27.6L192 209v42.2c0 41.8 30 80.1 71.7 84.3 47.9 4.9 88.3-32.7 88.3-79.6v-16h104c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8h-32v60c0 15.4-12.5 27.8-27.8 27.8h-24.1v24c0 17.8-14.4 32.2-32.2 32.2H211.3l-62.8 36.3c-7.7 4.4-10.3 14.2-5.9 21.9l8 13.9c4.4 7.7 14.2 10.3 21.9 5.9l51.6-29.9h115.8c36.9 0 68.1-25.1 77.4-59.2 31.5-9.2 54.7-38.4 54.7-72.8v-14.3c17.6-5.3 31.5-19 37.1-36.4L632 225.8c7.7-4.5 10.3-14.2 5.9-21.9z"
                    ></path>
                  </svg>
                </div>
                <router-link to="/contact-us"><button>Find A law Firm</button> <br /></router-link>
                <span class="btm">Free. Takes 2 min.</span>
              </div>
            </div>
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" class="flex w-full items-center justify-between px-2 bg-red-500 text-white py-3 text-sm  hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false">
                  <span class="font-medium text-gray-900">Filter By Primary Practise Areas</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="areasOpen=true">
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="areasOpen=false">
                      <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </button>
              </h3>
          
              <div class="p-6 bg-white" id="filter-section-0" v-if="areasOpen">
                <div class="space-y-4">
                  <div class="flex items-center" v-for="(practise,index) of practiseAreas" :key="index">
                    <input v-model="selectedPracticeAreas" :id="'filter-color-' + index" :name="practise" :value="practise" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"  @change="scrollToTop">
            <label :for="'filter-color-' + index" class="ml-3 text-sm text-gray-600">{{ practise }}</label>
                  </div>

                </div>
              </div>
            </div>
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" class="flex w-full items-center justify-between px-2 bg-red-500 text-white py-3 text-sm hover:text-gray-500" aria-controls="filter-section-1" aria-expanded="false">
                  <span class="font-medium text-gray-900">Filter By State</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="statesOpen=true">
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="statesOpen=false">
                      <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              <div class="p-6 bg-white" id="filter-section-1" v-if="statesOpen">
                <div class="space-y-4">
                  <div class="flex items-center" v-for="(state,index) of counties" :key="index">
                    <input v-model="selectedStates" :id="'filter-category-' + index" :name="state" :value="state" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" @change="  scrollToTop">
                    <label :for="'filter-category-' + index" class="ml-3 text-sm text-gray-600">{{ state }}</label>
                  </div>
                </div>
              </div>
            </div>

          </form>
          <div class="lg:col-span-3">
            <div class=" flex lg:hidden items-center justify-between ">
              <h3 class="font-semibold text-lg">Filters</h3>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" @click="showFiltersMobile=true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
</svg>

            </div>
            <a-list
                item-layout="horizontal"
                :pagination="pagination"
                :data-source="filteredAdvocates"
              >
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <div class="side-rgt-cards">
                    <div class="side-rgt-card">
                      <div class="profile-info">
                        <div class="p-top">
                          <div class="p-top-left flex-col md:flex-row">
                            <div class="p-image w-full md:w-[156px] md:mr-5">
                              <img
                                :src="item.profile_photo"
                                alt="profile photo"
                                class="h-50 w-full md:h-40 md:w-52 rounded-lg object-contain"
                                v-if="item.profile_photo"
                              />
                              <img
                                src="/images/dial-logo.png"
                                alt="profile photo"
                                class="h-50 w-full md:h-40 md:w-52 rounded-lg object-contain"
                                v-else
                              />
                            </div>
                            <div class="p-name">
                              <h2 class=" whitespace-nowrap">
                                {{ item.first_name }} {{ item.last_name }}
                              </h2>
                              <p
                                class="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap"
                              >
                                <a-icon type="solution" class="mx-5" />{{
                                  item.job_title
                                }}
                              </p>
                              <p
                                class="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap"
                              >
                                <a-icon type="environment" class="mx-5" />{{
                                  item.location
                                }}
                              </p>
                              <p class="flex items-center whitespace-nowrap">
                                <a-icon type="calendar" class="mx-5" />{{
                                  new Date().getFullYear() -
                                  new Date(item.practise_start).getFullYear()
                                }}Year(s) Experience
                              </p>
                              <p
                                class="lowercase flex items-center whitespace-nowrap"
                                v-if="item.current_employer"
                              >
                                <a-icon type="bank" class="mx-5" />{{
                                  item.current_employer
                                }}
                              </p>
                              <p class="flex items-center">
                                <a-icon
                                  type="clock-circle"
                                  class="mx-5"
                                />Member since
                                {{
                                  item.date_joined
                                    ? item.date_joined.toDate().getFullYear()
                                    : "2023"
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="p-btm">
                          <div class="p-btm-left">
                            <p>{{ item.biography.slice(0, 150) }}</p>
                            <div class="flex items-center gap-2">
                              <span class="text-red-600 whitespace-nowrap"
                                >Primary Practise Area :</span
                              >
                              <span class="whitespace-nowrap">
                                {{ item.specialisation }}
                              </span>
                            </div>
                             <div class="flex items-center gap-1">
                            <span class="text-red-600 whitespace-nowrap">Other Practise Areas:</span>
                            <div
                              class="my-5"
                              v-for="i of item.practise_areas"
                              :key="i"
                            >
                              <span class="mx-2 whitespace-nowrap">{{
                                i
                              }}</span>
                            </div>
                          </div>
                          </div>
                         
                        </div>
                      </div>
                      <div class="profile-access">
                        <button class="btn-p-success-tp"  @click="()=>{openMailClient(item)}">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            width="16"
                            class="inline-flex mr-2 align-bottom"
                          >
                            <path
                              d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"
                            ></path>
                          </svg>
                          Contact
                        </button>
                      <router-link :to="'/advocate/'+item.id">
                        <button class="btn-p-success">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            fill="currentColor"
                            width="16"
                            class="inline-flex mr-2 align-bottom"
                          >
                            <path
                              d="M208 256c35.35 0 64-28.65 64-64c0-35.35-28.65-64-64-64s-64 28.65-64 64C144 227.3 172.7 256 208 256zM464 232h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 232 464 232zM240 288h-64C131.8 288 96 323.8 96 368C96 376.8 103.2 384 112 384h192c8.836 0 16-7.164 16-16C320 323.8 284.2 288 240 288zM464 152h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 152 464 152zM512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416z"
                            ></path>
                          </svg>
                          View Profile
                        </button>
                      </router-link>
                        <button class="btn-p-success" @click="()=>{openPhoneDialer(item)}">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            width="16"
                            class="inline-flex mr-2 align-bottom"
                          >
                            <path
                              d="M480.3 320.3L382.1 278.2c-21.41-9.281-46.64-3.109-61.2 14.95l-27.44 33.5c-44.78-25.75-82.29-63.25-108-107.1l33.55-27.48c17.91-14.62 24.09-39.7 15.02-61.05L191.7 31.53c-10.16-23.2-35.34-35.86-59.87-30.19l-91.25 21.06C16.7 27.86 0 48.83 0 73.39c0 241.9 196.7 438.6 438.6 438.6c24.56 0 45.53-16.69 50.1-40.53l21.06-91.34C516.4 355.5 503.6 330.3 480.3 320.3zM463.9 369.3l-21.09 91.41c-.4687 1.1-2.109 3.281-4.219 3.281c-215.4 0-390.6-175.2-390.6-390.6c0-2.094 1.297-3.734 3.344-4.203l91.34-21.08c.3125-.0781 .6406-.1094 .9531-.1094c1.734 0 3.359 1.047 4.047 2.609l42.14 98.33c.75 1.766 .25 3.828-1.25 5.062L139.8 193.1c-8.625 7.062-11.25 19.14-6.344 29.14c33.01 67.23 88.26 122.5 155.5 155.5c9.1 4.906 22.09 2.281 29.16-6.344l40.01-48.87c1.109-1.406 3.187-1.938 4.922-1.125l98.26 42.09C463.2 365.2 464.3 367.3 463.9 369.3z"
                            ></path>
                          </svg>
                          Reveal Phone
                        </button>
                        <a :href="'//' + item.website" v-if="item.website">
                          <button class="btn-p-success">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 496 512"
                            fill="currentColor"
                            width="16"
                            class="inline-flex mr-2 align-bottom"
                          >
                            <path
                              d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-32 50.8v11.3c0 11.9-12.5 19.6-23.2 14.3l-24-12c14.9-6.4 30.7-10.9 47.2-13.6zm32 369.8V456c-110.3 0-200-89.7-200-200 0-29.1 6.4-56.7 17.6-81.7 9.9 14.7 25.2 37.4 34.6 51.1 5.2 7.6 11.2 14.6 18.1 20.7l.8.7c9.5 8.6 20.2 16 31.6 21.8 14 7 34.4 18.2 48.8 26.1 10.2 5.6 16.5 16.3 16.5 28v32c0 8.5 3.4 16.6 9.4 22.6 15 15.1 24.3 38.7 22.6 51.3zm42.7 22.7l17.4-46.9c2-5.5 3.3-11.2 4.8-16.9 1.1-4 3.2-7.7 6.2-10.7l11.3-11.3c8.8-8.7 13.7-20.6 13.7-33 0-8.1-3.2-15.9-8.9-21.6l-13.7-13.7c-6-6-14.1-9.4-22.6-9.4H232c-9.4-4.7-21.5-32-32-32s-20.9-2.5-30.3-7.2l-11.1-5.5c-4-2-6.6-6.2-6.6-10.7 0-5.1 3.3-9.7 8.2-11.3l31.2-10.4c5.4-1.8 11.3-.6 15.5 3.1l9.3 8.1c1.5 1.3 3.3 2 5.2 2h5.6c6 0 9.8-6.3 7.2-11.6l-15.6-31.2c-1.6-3.1-.9-6.9 1.6-9.3l9.9-9.6c1.5-1.5 3.5-2.3 5.6-2.3h9c2.1 0 4.2-.8 5.7-2.3l8-8c3.1-3.1 3.1-8.2 0-11.3l-4.7-4.7c-3.1-3.1-3.1-8.2 0-11.3L264 112l4.7-4.7c6.2-6.2 6.2-16.4 0-22.6l-28.3-28.3c2.5-.1 5-.4 7.6-.4 78.2 0 145.8 45.2 178.7 110.7l-13 6.5c-3.7 1.9-6.9 4.7-9.2 8.1l-19.6 29.4c-5.4 8.1-5.4 18.6 0 26.6l18 27c3.3 5 8.4 8.5 14.1 10l29.2 7.3c-10.8 84-73.9 151.9-155.5 169.7z"
                            ></path>
                          </svg>
                          Website
                          <svg
                            class="target ml-2 baseline"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            width="10"
                          >
                            <path
                              d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z"
                            ></path>
                          </svg>
                        </button>
                        </a>
                       
                      </div>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
          </div>
        </div>
      </section>
    </main>
  </div>
</div>

      </main>
    </div>



    <footerv-2-vue></footerv-2-vue>
  </div>
</template>

<script>
import Header from "../components/home/Header.vue";
import { mapState } from "vuex";
import CardInfo from "../components/Cards/CardInfo.vue";
import Footer from "../components/home/Footer.vue";
import Headerv2 from "../v2/Headerv2.vue";
import Footerv2Vue from "../v2/Footerv2.vue";

// inject svg spritesheet
fetch("https://s3-us-west-2.amazonaws.com/s.cdpn.io/450744/mock-logos.svg")
  .then((response) => response.text())
  .then((sprite) => {
    let figure = document.createElement("figure");
    figure.style.display = "none";
    figure.innerHTML = sprite;
    document.body.insertBefore(figure, document.body.children[0]);
  });
export default {
  components: {
    Header,
    CardInfo,
    Footer,
    Headerv2,
    Footerv2Vue,
    icon: { template: '<svg><use :xlink:href="use"/></svg>' },
  },
  props: ["use"],
  data() {
    return {
      title: "Find A Lawyer in Nigeria",
      description:
        "Our mission is to aid access to justice across Africa by linking verified legal professionals in the justice sector to the market",
      url: "https://dialalawyer.africa",
      image: "https://dialalawyer.africa/images/banner.jpg",
      modal: false,
      pagination: {
        onChange: (page) => {
          window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
        },
        pageSize: 7,
      },
      selectedPracticeAreas: [],
      selectedStates: [],
      areasOpen:true,
      statesOpen:true,
      showFiltersMobile:false
    };
  },
  computed: {
    ...mapState([
      "user",
      "advocates",
      "allAdvocates",
      "practiseAreas",
      "loading",
      "firebaseEror",
      "counties",
    ]),
    filteredAdvocates() {
      return this.advocates.filter(advocate => {
        const hasSelectedPracticeArea = this.selectedPracticeAreas.length === 0 || this.selectedPracticeAreas.includes(advocate.specialisation);
        const hasSelectedState = this.selectedStates.length === 0 || this.selectedStates.includes(advocate.location);
        return hasSelectedPracticeArea && hasSelectedState;
      });
    },


  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },

    clearFilter() {
    
      this.selectedPracticeAreas=[],
      this.selectedStates=[]
    },
    clearAllFilters() {
      Object.keys(this.filters).forEach(this.clearFilter);
    },
    openPhoneDialer(advocate) {
      const url = `tel:${advocate.phone}`;
      window.open(url);
    },
    openMailClient(advocate) {
      const url = `mailto:${advocate.email}`;
      window.open(url);
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchActiveAdvocates");
  },

  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content:
            "Our mission is to aid access to justice across Africa by linking verified legal professionals in the justice sector to the market",
        },
        // Open Graph (for Facebook, LinkedIn, etc.)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.image },
        { property: "og:url", content: this.url },

        // Twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image", content: this.image },
        { name: "twitter:url", content: this.url },
        // Add more tags as needed
      ],
    };
  },

  watch: {
    activeMenu(index, from) {
      if (index === from) return;

      this.$nextTick(() => {
        if (!this.$refs.menu || !this.$refs.menu[index]) {
          this.dropdown.height = 0;
        } else {
          this.dropdown.height = `${
            this.$refs.menu[index].clientHeight + 16
          }px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  color: #3d5358;
  margin: 0 auto;

  &__list {
    position: relative;
    margin-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 5rem;
    backface-visibility: hidden;
  }
}

.company {
  position: relative;
  width: calc(100% / 2 - 1rem);
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 0 0 1px #c5d0d1;
  backface-visibility: hidden;
  transform-origin: 10% 50%;
  z-index: 1;

  @media (min-width: 800px) {
    width: calc(100%);
  }

  &-move {
    transition: all 600ms ease-in-out 50ms;
  }
  &-enter-active {
    transition: all 300ms ease-out;
  }

  &-leave-active {
    transition: all 200ms ease-in;
    position: absolute;
    z-index: 0;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter {
    transform: scale(0.9);
  }

  &__data {
    line-height: 1.3;
  }
  &__label {
    font-size: 0.8rem;
  }
  &__rating {
    text-align: center;
  }

  &__info {
    padding: 0 0.75rem;
    text-align: center;
  }

  &__logo {
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
  }

  &__name {
    height: 2.5rem;
    margin: 0.75rem 0;
    font-size: 1.3rem;
    font-weight: 200;
    text-align: center;
  }

  &__slogan {
    height: 2rem;
    text-align: center;
    font-weight: 400;
    text-transform: capitalize;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 0.5rem 0.75rem;
    background-color: rgba(#000000, 0.1);
    border-top: 1px solid #000000;
  }

  &__country:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  margin: 0 1rem;
  padding: 2rem 0.5rem 1rem;
  border-bottom: 1px solid #000000;

  &__controls {
    display: flex;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  &__label {
    position: relative;
    margin-left: 1rem;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;

    &::after {
      content: "\00d7";
      display: inline-block;
      color: transparent;
      width: 0.5rem;
      font-weight: 400;
      transform: scale(0);
      transition: transform 150ms ease-in-out;
    }

    &--clear {
      color: #f68185;
      opacity: 0;
      transform: translate3d(-25%, 0, 0);
      pointer-events: none;
      transition: all 275ms ease-in-out;
    }

    &--filter ~ &--clear {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: auto;
    }

    &--filter::after,
    &--active::after {
      transform: scale(1);
    }

    &--filter::after {
      content: "\2022";
      color: #000000;
    }

    &--active::after {
      content: "\00d7";
      color: #f68185;
    }
  }
}

.dropdown {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 350ms;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-image: linear-gradient(to top, white, rgba(white, 0));
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave,
  &-enter-to {
    opacity: 1;
  }

  &-enter-active,
  &-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity 200ms ease-in-out;
  }

  &-enter-active {
    transition-delay: 100ms;
  }
}

.filters {
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &__item {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid #c5d0d1;
    border-radius: 6px;
    font-size: 0.8rem;
    line-height: 1.35;
    cursor: pointer;
    transition: all 275ms;

    &:hover {
      border-color: #000000;
    }

    &--active {
      color: white;
      border-color: #000000;
      background-color: #000000;
    }
  }

  &__rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;
  }

  &__range {
    width: 200px;
    margin-top: 1rem;
    color: inherit;

    &::-webkit-slider-thumb {
      width: 0.8rem;
      height: 0.8rem;
      margin-top: calc(-0.4rem + 2px);
      border-radius: 100%;
      background-color: currentColor;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      background-image: linear-gradient(to right, white, #46d2c4);
    }
  }
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(white, 0.6);
  z-index: 1;

  &-enter-active,
  &-leave-active {
    transition: opacity 350ms;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave,
  &-enter-to {
    opacity: 1;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 500px;
    min-height: 250px;
    padding: 1.5rem 1rem;
    background-color: white;
    border: 1px solid #c5d0d1;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 0.5rem 1.75rem -0.25rem rgba(#3d5358, 0.4);
  }

  &__title {
    font-weight: 400;
    font-size: 1.5rem;
  }

  &__link {
    margin-top: 1.5rem;
    position: relative;
    font-size: 1.2rem;
    font-weight: 300;
    z-index: 0;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      z-index: -1;
      transition: background-color 225ms ease-out;
    }

    &:hover::after {
      background-color: #46d2c4;
    }
  }

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 1rem;
    font-size: 1.75rem;
    font-weight: 400;
    opacity: 0.5;
    transition: opacity 150ms ease-out;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
